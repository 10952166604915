














































































import {Component, Vue} from 'vue-property-decorator'
import {CoursePtBuyDto} from '@/dto/CourseDto';
import {getPtBuyCourseByIdAsync} from '@/api/course'
import {makeOrder} from '@/api/courseOrder';

@Component({})
export default class PtBuy extends Vue {
  private courseInfo: CoursePtBuyDto = {
    coachId: '',
    coachName: '',
    courseId: '',
    courseTime: '',
    faceImg: '',
    helpline: '',
    name: '',
    phone: '',
    price: 0,
    remark: ''

  }
  private loading = false
  private courseBuyTime = 1

  get courseId() {
    return this.$route.query.courseId;
  }

  get coachId() {
    return this.$route.query.coachId;
  }

  private async mounted() {
    await this.getBuyInfo()
  }

  private async getBuyInfo() {
    if (this.courseId && this.coachId) {
      this.courseInfo = await getPtBuyCourseByIdAsync(this.courseId, this.coachId)
    }
  }

  private async handleClickCoach() {
    await this.$router.push({
      path: '/course/coach-info',
      query: {
        coachId: this.coachId
      }
    })
  }

  private async handleBuyOrder() {
    if (this.courseId && this.coachId && this.courseInfo.price > 0) {
      const orderData = {
        courseId: this.courseId,
        coachId: this.coachId,
        remark: this.courseInfo.name,
        orderState: 0,
        price: this.courseInfo.price,
        discountPrice: this.courseInfo.price * this.courseBuyTime,
        courseTime: this.courseBuyTime,
        courseEnum: 2
      }

      this.loading = true
      const result = await makeOrder(orderData);
      this.loading = false
      await this.$router.push({
        path: '/user/pt-order',
        query: {
          orderId: result.id
        }
      });
    }
  }
}
